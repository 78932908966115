$gray: #999;
$light-gray: #EEE;

/* Space out content a bit */
body {
    padding-top: 20px;
}

.header {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.footer {
    /* padding-top: 19px; */
    color: $gray;
    border-top: 1px solid $light-gray;
    text-align: center;
    font-size: 0.8em;

    a:link, a:visited {
        color: $gray;
    }
}

#topbar {
    padding-top: 10px;
}

.fa {
    color: $gray;
}
a > .fa {
    // make sure that font-awesome icons are underlined in links
    display: inline;
}

/* /\* Customize container *\/ */
/* @media (min-width: 768px) { */
/*     .container { */
/*         max-width: 730px; */
/*     } */
/* } */

/* Jumbotron */
.jumbotron {
    text-align: justify;
    padding-top: 1.5em;
    padding-bottom: 0.5em;
    font-size: 125%
}

/* Group logo */
#logo {
    // width: 154px;
    // height: 35px;
    // margin: 2px 0 8px 0;
    margin-top: -15px;
    margin-bottom: -10px;
}

/* Front page news. */
ul.news {
    
    font-size: 1.25em;

    .date {
        color: $gray;
        font-weight: bold;
    }

    > li {
        margin-top: 1em;
    }
}

@media (min-width: 576px) {
    .project-people { column-count: 2 }
    .card-columns   { column-count: 2 }
}

@media (min-width: 768px) {
    .role.grad      { column-count: 2 }
    .project-people { column-count: 3 }
    .card-columns   { column-count: 3 }
}

.title img {
    max-width: 50%;
}

section {
    margin-bottom: 2em;
}

.profile {
    object-fit: cover; // makes sure it's cropped
}

.noprofile {
    circle {
        fill: $light-gray;
    }
    text {
        font-weight: 700;
        fill: white;
    }
}

.card {

    .profile {
        height: 40px;
        width: 40px;
        margin: 3px;
    }

    .card-footer { padding: 0.5em }
}

.person {

    margin-bottom: 10px;

    .profile {
        height: 70px;
        width: 70px;
    }

    .bio {
        color: $gray;
    }

    .person-name {
        margin: 0;
    }
}

.person-without-image {
    margin: 0;

    .bio {
        margin-left: 1em;
    }
}

svg {
    // make the alignment just like img from bootstrap's reboot.scss
    vertical-align: middle;
}

// a {
//     color: rgb(84, 92, 136);
// }

/* Usually used for schedules */
table {
    width: 1000px;
    border-collapse: collapse;
    border-spacing: 0px;
    background: #f5f5f5;
    border: 1px solid #ddd;
}
td, th { text-align: left; padding: 8px; }
tr { border: 1px solid #ddd; }

/* */
.talk-entry {
    position: relative;
    display: block;
    padding: 10px 15px;
    border: 1px solid #333;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 10px;
}

.talk-entry-date {
    float: right;
}
